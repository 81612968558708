<template>
  <v-col
    ><s-crud
      add
      edit
      search-input
      :filter="filter"
      remove
      @doubleClick="dobleClick"
      title="Restricciones de Acceso por Área"
      :config="config"
      @save="save($event)"
    >
      <template scope="props">
        <v-col>
          <v-row justify="center">
            <v-col cols=12 lg="4" class="s-col-form">
              <s-select-area :disabled="props.item.AreID"  autofocus v-model="props.item.AreID" :text="props.item.AreName" label="Nombre de área" />
            </v-col>
            <v-col cols="12" lg="2" class="s-col-form">
              <s-date v-model="props.item.AraBeginDate" label="Inicio" />
            </v-col>
            <v-col cols="12" lg="2" class="s-col-form">
              <s-date v-model="props.item.AraEndDate" label="Fin" />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" lg="8" class="s-col-form"> <s-text v-model="props.item.AraReason" label="Motivo" /> </v-col>
          </v-row>
        </v-col>
      </template> </s-crud
  ></v-col>
</template>

<script>
import _sArea from "@/services/HumanResource/HmnAccessRestrictionService.js";
export default {
  name: "RequirementService",
  components: {},
  props: {},

  data: () => ({
    filter: { TypeArea: 0, AreID: 0 },
    config: {
      model: {
        AraID: "ID",
        AraBeginDate: "date",
        AraEndDate: "date",
      },
      service: _sArea,
      headers: [
        { text: "ID", value: "AraID", sortable: false, width: 10 },
        { text: "Área", value: "AreName", sortable: false },
        { text: "Fecha de Inicio", value: "AraBeginDate", sortable: false, width: 150 },
        { text: "Fecha de Fin", value: "AraEndDate", sortable: false, width: 150 },

        { text: "Motivo", value: "AraReason", sortable: false },
      ],
    },
  }),
  methods: {
    save(item) {
      if (item.AreID == null || item.AreID == 0) {
        this.$fun.alert("Complete Área", "warning");
        return;
      } else {
        if (item.AraReason == null) {
          this.$fun.alert("Ingrese el motivo de restriccion", "warning");
          return;
        }
      }

      item.save();
    },
    dobleClick(item) {
      this.$emit("dblClick", item);
    },
  },
  created() {},
};
</script>
