import Service from "../Service";

const resource = "/humanresource/accessRestriction/";

export default {

    save(list, requestID) {
        return Service.post(resource + "save", list, {
            params: { requestID: requestID }
        });
    },

    pagination(dtr, requestID) {
        return Service.post(resource + "pagination", dtr, {
            params: { requestID: requestID },
        });
    },

};